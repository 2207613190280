.scrollert {
  position: relative;
  overflow: hidden;
}
.scrollert > .scrollert-content {
  position: absolute;
  overflow: hidden;
  box-sizing: content-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
}
.scrollert > .scrollert-content:focus {
  outline: none;
}
.scrollert > .scrollert-content:not(.scrollert-disabled) {
  -ms-overflow-style: none;
}
.scrollert > .scrollert-content:not(.scrollert-disabled)::-webkit-scrollbar {
  display: none;
}
.scrollert > .scrollert-content.scrollert-disabled {
  overflow: auto;
}
.scrollert > .scrollert-scrollbar {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.scrollert > .scrollert-scrollbar:before {
  content: '';
  position: absolute;
  overflow: hidden;
  opacity: 0.5;
  border-radius: 0.5em;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 150ms ease, opacity 500ms ease;
}
.scrollert > .scrollert-scrollbar > .scrollert-track {
  position: absolute;
}
.scrollert > .scrollert-scrollbar > .scrollert-track:before {
  content: '';
  position: absolute;
  overflow: hidden;
  border-radius: 0.5em;
  background: rgba(0, 0, 0, 0.25);
  transition: all 150ms ease, opacity 500ms ease;
}
.scrollert > .scrollert-scrollbar-y {
  left: auto;
  width: 2em;
}
.scrollert > .scrollert-scrollbar-y > .scrollert-track {
  left: 0;
  right: 0;
  min-height: 45px;
}
.scrollert > .scrollert-scrollbar-y:before,
.scrollert > .scrollert-scrollbar-y > .scrollert-track:before {
  top: 0.85em;
  bottom: 0.85em;
  left: 0.75em;
  right: 0.75em;
  opacity: .75;
}
.scrollert > .scrollert-scrollbar-x {
  top: auto;
  height: 2em;
}
.scrollert > .scrollert-scrollbar-x > .scrollert-track {
  top: 0;
  bottom: 0;
  min-width: 45px;
}
.scrollert > .scrollert-scrollbar-x:before,
.scrollert > .scrollert-scrollbar-x > .scrollert-track:before {
  left: 0.85em;
  right: 0.85em;
  top: 0.75em;
  bottom: 0.75em;
}
.scrollert.scrollert-trackdrag-y > .scrollert-scrollbar-y > .scrollert-track:before,
.scrollert > .scrollert-scrollbar-y:hover > .scrollert-track:before {
  background: rgba(0, 0, 0, 0.5);
}
.scrollert.scrollert-trackdrag-y > .scrollert-scrollbar-y:before,
.scrollert > .scrollert-scrollbar-y:hover:before,
.scrollert.scrollert-trackdrag-y > .scrollert-scrollbar-y > .scrollert-track:before,
.scrollert > .scrollert-scrollbar-y:hover > .scrollert-track:before {
  left: 0.65em;
  right: 0.65em;
}
.scrollert.scrollert-trackdrag-x > .scrollert-scrollbar-x > .scrollert-track:before,
.scrollert > .scrollert-scrollbar-x:hover > .scrollert-track:before {
  background: rgba(0, 0, 0, 0.5);
}
.scrollert.scrollert-trackdrag-x > .scrollert-scrollbar-x:before,
.scrollert > .scrollert-scrollbar-x:hover:before,
.scrollert.scrollert-trackdrag-x > .scrollert-scrollbar-x > .scrollert-track:before,
.scrollert > .scrollert-scrollbar-x:hover > .scrollert-track:before {
  top: 0.65em;
  bottom: 0.65em;
}
.scrollert.scrollert-axis-x.scrollert-axis-y > .scrollert-scrollbar-y {
  bottom: 1em;
}
.scrollert.scrollert-axis-x.scrollert-axis-y > .scrollert-scrollbar-x {
  right: 1em;
}
.scrollert:hover > .scrollert-scrollbar:before {
  opacity: 1;
}
.scrollert:hover > .scrollert-scrollbar > .scrollert-track:before {
  opacity: 1;
}
.scrollert.scrollert-trackdrag-x > .scrollert-content,
.scrollert.scrollert-trackdrag-y > .scrollert-content {
  pointer-events: none;
}
.scrollert.scrollert-white.scrollert-trackdrag-x > .scrollert-scrollbar-x > .scrollert-track:before,
.scrollert.scrollert-white > .scrollert-scrollbar-x:hover > .scrollert-track:before {
  background: rgba(255, 255, 255, 0.75);
}
.scrollert.scrollert-white.scrollert-trackdrag-y > .scrollert-scrollbar-y > .scrollert-track:before,
.scrollert.scrollert-white > .scrollert-scrollbar-y:hover > .scrollert-track:before {
  background: rgba(255, 255, 255, 0.75);
}
.scrollert.scrollert-white > .scrollert-scrollbar:before {
  background: rgba(255, 255, 255, 0.25);
}
.scrollert.scrollert-white > .scrollert-scrollbar > .scrollert-track:before {
  background: rgba(255, 255, 255, 0.5);
}
.scrollert.scrollert-white > .scrollert-scrollbar:hover > .scrollert-track:before {
  background: rgba(255, 255, 255, 0.75);
}

@outer-size: 2em;

@opposite-offset: .85em;
@adjacent-offset: .75em;
@xy-intersection-offset: 1em;

@hover-offset: .65em;

@border-radius: .5em;

@min-track-size: 45px;

@opacity-scrollbar: .5;
@opacity-scrollbar-hover: 1;

@dimensions-transition-duration: 150ms;
@opacity-transition-duration: 500ms;

@color-scrollbar: rgba(0,0,0,.05);
@color-scrollbar-track: rgba(0,0,0,.25);
@color-scrollbar-track-hover: rgba(0,0,0,.5);

@color-white-scrollbar: rgba(255,255,255,.25);
@color-white-scrollbar-track: rgba(255,255,255,.5);
@color-white-scrollbar-track-hover: rgba(255,255,255,.75);

.scrollert
{
    position: relative;
    overflow: hidden;

    > .scrollert-content
    {
        position: absolute;
        overflow: hidden;
        box-sizing: content-box;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-overflow-scrolling: touch;

        &:focus
        {
            outline: none;
        }

        &:not(.scrollert-disabled)
        {
            &::-webkit-scrollbar
            {
                display: none;
            }

            -ms-overflow-style: none;
        }

        &.scrollert-disabled
        {
            overflow: auto;
        }
    }

    > .scrollert-scrollbar
    {
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &:before
        {
            content: '';
            position: absolute;
            overflow: hidden;

            opacity: @opacity-scrollbar;
            border-radius: @border-radius;
            background: @color-scrollbar;
            box-shadow: inset 2px 2px 6px rgba(0,0,0,.1);

            transition: all @dimensions-transition-duration ease, opacity @opacity-transition-duration ease;
        }

        > .scrollert-track
        {
            position: absolute;

            &:before
            {
                content: '';
                position: absolute;
                overflow: hidden;

                border-radius: @border-radius;
                background: @color-scrollbar-track;

                transition: all @dimensions-transition-duration ease, opacity @opacity-transition-duration ease;
            }
        }

        &-y
        {
            left: auto;
            width: @outer-size;

            > .scrollert-track
            {
                left: 0;
                right: 0;
                min-height: @min-track-size;
            }

            &:before, > .scrollert-track:before
            {
                top: @opposite-offset;
                bottom: @opposite-offset;
                left: @adjacent-offset;
                right: @adjacent-offset;
                opacity: .75;
            }
        }

        &-x
        {
            top: auto;
            height: @outer-size;

            > .scrollert-track
            {
                top: 0;
                bottom: 0;

                min-width: @min-track-size;
            }

            &:before, > .scrollert-track:before
            {
                left: @opposite-offset;
                right: @opposite-offset;
                top: @adjacent-offset;
                bottom: @adjacent-offset;
            }
        }
    }

    //Change dimensions when hovering or dragging the track
    &.scrollert-trackdrag-y > .scrollert-scrollbar-y,
    > .scrollert-scrollbar-y:hover
    {
        > .scrollert-track:before
        {
            background: @color-scrollbar-track-hover;
        }

        &:before, > .scrollert-track:before
        {
            left: @hover-offset;
            right: @hover-offset;
        }
    }

    &.scrollert-trackdrag-x > .scrollert-scrollbar-x,
    > .scrollert-scrollbar-x:hover
    {
        > .scrollert-track:before
        {
            background: @color-scrollbar-track-hover;
        }

        &:before, > .scrollert-track:before
        {
            top: @hover-offset;
            bottom: @hover-offset;
        }
    }

    //Both scrollbars are available
    &.scrollert-axis-x.scrollert-axis-y
    {
        > .scrollert-scrollbar-y
        {
            bottom: @xy-intersection-offset;
        }

        > .scrollert-scrollbar-x
        {
            right: @xy-intersection-offset;
        }
    }


    //Hover behaviour
    &:hover > .scrollert-scrollbar
    {
        //On the content pane
        &:before
        {
            opacity: @opacity-scrollbar-hover;
        }

        > .scrollert-track:before
        {
            opacity: @opacity-scrollbar-hover;
        }
    }

    //Disable pointer events while dragging the track. Makes track drag possible on content containing an iframe
    &.scrollert-trackdrag-x,
    &.scrollert-trackdrag-y
    {
        > .scrollert-content
        {
            pointer-events: none;
        }
    }

    &.scrollert-white
    {
        &.scrollert-trackdrag-x > .scrollert-scrollbar-x,
        > .scrollert-scrollbar-x:hover
        {
            > .scrollert-track:before
            {
                background: @color-white-scrollbar-track-hover;
            }
        }

        &.scrollert-trackdrag-y > .scrollert-scrollbar-y,
        > .scrollert-scrollbar-y:hover
        {
            > .scrollert-track:before
            {
                background: @color-white-scrollbar-track-hover;
            }
        }

        > .scrollert-scrollbar
        {
            &:before
            {
                background: @color-white-scrollbar;
            }

            > .scrollert-track:before
            {
                background: @color-white-scrollbar-track;
            }

            &:hover > .scrollert-track:before
            {
                background: @color-white-scrollbar-track-hover;
            }

        }
    }
}